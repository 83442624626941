/* =========
  Expanding Panels
  This Code is Licensed by Will-Myers.com
========== */

.wm-expanding-panels {
  --panel-height: 60vh;
  --active-width: 2;
  --horizontal-alignment: start;
  --text-alignment: start;
  --vertical-alignment: center;
  --transition-duration: 1000ms;
  --background: var(--siteBackgroundColor);
  --background-opacity: 0.5;
  --border-radius: 20px;
  --content-width: auto;
  --display-title: block;
  --display-excerpt: block;
  --display-button: inline-block;
  --mobile-panel-height: 11vh;
  --active-mobile-panel-height: 60vh;
}


[data-wm-plugin="expanding-panels"] .wm-grid {
  top: 0px;
  width: 100%;
  display: flex;
  margin: auto;
  max-width: var(--sqs-site-max-width);
}

[data-wm-plugin="expanding-panels"] .wm-panel {
  background-size: cover;
  display: grid;
  padding: 20px;
  flex: 1;
  transition: var(--transition-duration);
  height: var(--panel-height);
  position: relative;
  overflow: hidden;
  align-items: center;
}

[data-wm-plugin="expanding-panels"] .wm-content-wrapper {
  text-align: var(--text-alignment);
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: grid;
  place-items: var(--vertical-alignment) var(--horizontal-alignment);
  opacity: 0;
  padding: 20px;
}

[data-wm-plugin="expanding-panels"] .wm-content {
  position: relative;
  top: 0;
  left: 0;
  padding: 20px;
  width: var(--content-width);
}

[data-wm-plugin="expanding-panels"] .panel-background {
  position: absolute;
  height: 100%;
  width: 100%;
  background: var(--background);
  opacity: var(--background-opacity);
  position: absolute;
  border-radius: var(--border-radius);
  top: 0;
  left: 0;
}

[data-wm-plugin="expanding-panels"] .vertical-title {
  position: absolute;
  display: flex;
  justify-self: center;
  transition: opacity var(--transition-duration);
}

[data-wm-plugin="expanding-panels"] .vertical-title h4 {
  writing-mode: vertical-lr;
  text-orientation: mixed;
  transform: rotate(180deg);
  justify-self: flex-start;
}

[data-wm-plugin="expanding-panels"] .wm-panel.panel-active .vertical-title {
  opacity: 0;
}

[data-wm-plugin="expanding-panels"] .wm-button {
  display: var(--display-button);
  z-index: 2;
  position: relative;
  width: fit-content;
}

[data-wm-plugin="expanding-panels"] .title {
  display: var(--display-title);
  z-index: 2;
  position: relative;
}


[data-wm-plugin="expanding-panels"] .excerpt {
  display: var(--display-excerpt);
  z-index: 2;
  position: relative;
}

[data-wm-plugin="expanding-panels"] .title h4 {
  margin: 0px;
}

[data-wm-plugin="expanding-panels"] .wm-panel.panel-active .wm-content-wrapper {
  opacity: 1;
  transition: opacity var(--transition-duration);
  transition-delay: calc(var(--transition-duration) / 2);
}


[data-wm-plugin="expanding-panels"] .wm-panel.panel-active {
  flex: var(--active-width);
}

[data-wm-plugin="expanding-panels"] .wm-panel.panel-inactive {
  filter: brightness(0.4);
}

[data-wm-plugin="expanding-panels"] .wm-panel:not(.panel-active) .wm-content-wrapper a {
  visibility: hidden;
}

[data-wm-plugin="expanding-panels"] .wm-panel .wm-content-wrapper a {
  visibility: visible;
  transition: visibility 0s linear 0.2s;
}


@media only screen and (min-width: 767px) {
  [data-wm-plugin="expanding-panels"] .wm-panel-link {
      display:block;
      cursor:pointer;
      position:absolute;
      top:0;
      height:100%;
      width:100%;
      z-index:9;
    }
}

@media screen and (max-width: 767px) {
[data-wm-plugin="expanding-panels"] .wm-grid {
    flex-direction: column; /* Stack panels vertically on mobile */
  }

  [data-wm-plugin="expanding-panels"] .wm-panel {
    display: flex;
    flex-direction: column;
    min-height: var(--mobile-panel-height);
    overflow: hidden;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  }

  [data-wm-plugin="expanding-panels"] .wm-button {
    justify-self: center;
  }

  [data-wm-plugin="expanding-panels"] .wm-panel .vertical-title {
    display: block;
  }

  [data-wm-plugin="expanding-panels"] .vertical-title h4 {
  writing-mode: horizontal-tb;
  text-orientation: mixed;
  transform: rotate(0deg);
  justify-self: flex-start;
  
  margin: 0px;
}
[data-wm-plugin="expanding-panels"] .wm-panel .wm-content-wrapper {
    visibility: hidden;
    text-align: var(--text-alignment);
  }

 [data-wm-plugin="expanding-panels"] .wm-panel.panel-active .wm-content-wrapper {
   visibility: visible;
  }
  
 [data-wm-plugin="expanding-panels"] .wm-panel.panel-active {
   min-height: var(--active-mobile-panel-height);
   transition: var(--transition-duration);
 }
}


@media only screen and (min-width: 766px) {
[data-wm-plugin="expanding-panels"] .wm-grid.vertical {
flex-direction: column;
}

[data-wm-plugin="expanding-panels"] .wm-grid.vertical .vertical-title > * {
writing-mode: unset;
transform: none;
}

[data-wm-plugin="expanding-panels"] .wm-grid.vertical .wm-panel {
  min-height: var(--desktop-vertical-closed-height, 20vh);
}

[data-wm-plugin="expanding-panels"] .wm-grid.vertical .wm-panel.panel-active {
  min-height: var(--desktop-vertical-active-height, 40vh);
}
}

.sqs-edit-mode-active .code-block [data-wm-plugin="expanding-panels"] {
  color: transparent;
  background: transparent;
  font-size: 0;
  position: absolute;
  line-height: 0;
  width: 100%;
  height: 25px;
  border: 1px dashed var(--headingLargeColor);
}

.sqs-edit-mode-active .code-block [data-wm-plugin="expanding-panels"]::after {
  content: 'Expanding Panels';
  color: var(--headingLargeColor);
  font-size: 12px;
  position: absolute;
  top: 50%;
  transform:translateX(25%);
}
